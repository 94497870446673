<template>
  <div
    class="auth-wrapper auth-v2 registration-page"
    :class="{ 'modal-register-page': fromModal }"
  >
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <h2 class="brand-text text-primary ml-1 d-none-mobile">
          <b-img
            style="width: 35%"
            :src="require('@/assets/images/app-images/logo-superapp.svg')"
            @click="$router.push({ path: '/' })"
          />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        v-if="!fromModal"
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Registration"
            class="w-100"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        :lg="fromModal ? 12 : 4"
        class="d-flex align-items-center auth-bg px-2"
        :class="{ 'p-lg-5': !fromModal, 'p-0': fromModal }"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-10"
            :class="{ 'text-center': fromModal }"
          >
            <img
              class="mt-5 mb-1 w-100"
              :src="require('@/assets/images/app-images/mom_r_wide.svg')"
            >
          </b-card-title>
          <div
            v-if="!fromModal"
            class="d-flex justify-content-end"
          >
            <locale
              class="locale-wrapper"
              style="list-style: none"
            />
          </div>
          <!-- form -->
          <validation-observer ref="registrationValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <b-form-group
                :label="$t('SIGN UP VIA')"
                class="text-center"
              >
                <b-form-radio-group
                  v-model="forgetType"
                  :disabled="registrationSteps.verifyOtp"
                  class="demo-inline-spacing mb-1"
                >
                  <b-form-radio value="email">
                    <img
                      class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-mail-94.png')"
                    >
                  </b-form-radio>
                  <b-form-radio value="mobile">
                    <img
                      class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-smartphone-94.png')"
                    >
                  </b-form-radio>
                  <b-form-radio value="whatsapp">
                    <img
                      class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-whatsapp-94.png')"
                    >
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                v-if="forgetType != 'email' || (registrationSteps.normal && $route.meta.sharedType == 'user')"
                :label="$t('Phone Number')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <VuePhoneNumberInput
                    v-model="registrationInfo.userPhoneNumber"
                    :disabled="forgetType != 'email' ? registrationSteps.verifyOtp : false"
                    placeholder="+91 0000000000"
                    :state="errors.length > 0 ? false : null"
                    @update="onUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationSteps.sendOtp && forgetType == 'email' && !registrationSteps.normal"
                :label="$t('Email')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="registrationInfo.userEmail"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="registrationSteps.verifyOtp"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationSteps.verifyOtp"
                :label="$t('Verify OTP')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="VerifyOtp"
                  rules="required"
                >
                  <b-form-input
                    v-model="registrationInfo.otp"
                    :disabled="registrationSteps.normal"
                    :state="errors.length > 0 ? false : null"
                    placeholder="0123"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="forgetType == 'email' && registrationSteps.normal"
                :label="$t('Email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="registrationInfo.userEmail"
                    type="email"
                    :disabled="forgetType === 'email'"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'shop'"
                :label="$t('Shop Name')"
              >
                <b-form-input
                  v-model="registrationInfo.store_name"
                  placeholder="all in one"
                />
              </b-form-group> -->
              <!-- <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'shop'"
                :label="$t('Shop Type')"
              >
                <model-select
                  v-model="registrationInfo.store_type_id"
                  :options="shopTypeOptions"
                />
              </b-form-group> -->
              <!-- <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'provider'"
                :label="$t('Service Category')"
              >
                <multiselect
                  v-model="registrationInfo.provider_service_id"
                  label="name"
                  track-by="id"
                  :options="providerServiceOptions"
                  :multiple="true"
                  :close-on-select="false"
                  :taggable="true"
                />
              </b-form-group> -->
              <!-- <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'provider'"
                :label="$t('Company')"
              >
                <b-form-input
                  v-model="registrationInfo.company_name"
                  placeholder="john"
                />
              </b-form-group> -->
              <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'user'"
                :label="$t('First Name')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="registrationInfo.firstName"
                    placeholder="john"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'user'"
                :label="$t('Last Name')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="registrationInfo.lastName"
                    placeholder="deo"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationSteps.normal"
                :label="$t('Password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="registrationInfo.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationSteps.normal"
                :label="$t('Confirm Password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="registrationInfo.confirmPassword"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'user'"
                :label="$t('Gender')"
              >
                <b-form-select
                  v-model="registrationInfo.gender"
                  :options="genderOptions"
                />
              </b-form-group> -->
              <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'user'"
                :label="$t('Country')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <model-select
                    v-model="registrationInfo.countryCode"
                    :options="countryOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'user'"
                :label="$t('City')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <model-select
                    v-model="registrationInfo.cityCode"
                    :options="cityOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group
                v-if="registrationSteps.normal && $route.meta.sharedType == 'user'"
                :label="$t('Referral Code')"
              >
                <b-form-input v-model="registrationInfo.referralCode" />
              </b-form-group> -->
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ buttonText }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('Already have an account?') }} </span>
            <b-link
              v-if="!fromModal"
              :to="{ name: $route.meta.sharedType == 'provider' ? 'apps-provider-login' : $route.meta.sharedType == 'shop' ? 'apps-shop-login' : 'apps-login' }"
            >
              <span>&nbsp;{{ $t('Log In') }}</span>
            </b-link>
            <b-link
              v-else
              href="javascript: void(0);"
              @click="$emit('hide-registration')"
            >
              <span>&nbsp;{{ $t('Log In') }}</span>
            </b-link>
          </b-card-text>
          <!-- divider -->
          <div
            v-if="$route.meta.sharedType === 'user'"
            class="divider my-2"
          >
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div>

          <!-- social buttons -->
          <div
            class="auth-footer-btn d-flex justify-content-center"
          >
            <facebook-login
              app-id="518236876494478"
              class="face-book-login-button px-0"
              :login-label="''"
              @login="onLogin"
              @get-initial-status="getUserData"
              @sdk-loaded="sdkLoaded"
              :logoutLabel="' '"
            />
            <div id="g_id_onload"></div>
            <div class="g_id_signin ml-1"></div>
          </div>
          <b-card-text class="text-left mt-2">
            <span>{{ $t('By continuing, you agree to our') }} </span>
            <b-link :to="{ path: '/terms-and-condition' }">
              <span>&nbsp;{{ $t('Terms of Use') }}</span>
            </b-link>
            {{ $t('and') }}
            <b-link :to="{ path: '/privacy-policy' }">
              <span>&nbsp;{{ $t('Privacy Policy') }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BFormRadio, BFormRadioGroup, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue'
import { required, email } from '@validations'
import { ModelSelect } from 'vue-search-select'
// import Multiselect from 'vue-multiselect'
import facebookLogin from 'facebook-login-vuejs'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useLoginUi } from './useAuth'
import { fbLogin } from './init-facebook-sdk'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import 'vue-search-select/dist/VueSearchSelect.css'
// import 'vue-multiselect/dist/vue-multiselect.min.css'
import { useUserUi } from '../users/useUser'
import jwt_decode from "jwt-decode";

export default {
  components: {
    facebookLogin,
    BRow,
    // Multiselect,
    BFormRadioGroup,
    BFormRadio,
    BCol,
    ModelSelect,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  props: {
    fromModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSocialLogin: false,
      isConnected: false,
      FB: null,
      registrationInfo: {
        password: '',
        userEmail: '',
        confirmPassword: '',
        userPhoneNumber: '',
        firstName: '',
        lastName: '',
        referralCode: '',
        gender: '',
        countryCode: '',
        cityCode: '',
        otp: '',
        phoneCode: '',
        store_name: '',
        store_type_id: '',
      },
      registrationSteps: {
        sendOtp: true,
        verifyOtp: false,
        normal: false,
      },
      shopTypes: [],
      countryOptions: [],
      genderOptions: [
        {
          text: 'Select Gender',
          value: '',
        },
        {
          text: 'Male',
          value: 'MALE',
        },
        {
          text: 'Female',
          value: 'FEMALE',
        },
        {
          text: 'Other',
          value: 'GENERAL',
        },
      ],
      cityOptions: [],
      countryList: [],
      shopTypeOptions: [],
      providerServiceOptions: [],
      phoneNumberPayload: null,
      status: '',
      forgetType: 'whatsapp',
      sideImg: require('@/assets/images/app-images/login-animation.png'),
      required,
      email,
    }
  },
  computed: {
    buttonText() {
      // eslint-disable-next-line no-nested-ternary
      return this.registrationSteps.normal ? 'Sign up' : this.registrationSteps.verifyOtp ? 'Verify OTP' : 'Send OTP'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/app-images/login-animation.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    'registrationInfo.countryCode': {
      immediate: true,
      deep: true,
      handler() {
        if (this.registrationInfo && this.registrationInfo.countryCode) {
          this.onCountryChange()
        }
      },
    },
  },
  mounted() {
    window.google.accounts.id.initialize({
      client_id: this.$superApp.google.clientId,
      callback: (res) => {
        this.OnGoogleAuthSuccess(res.credential)
      }
    });
    const parent = document.getElementById('g_id_onload');
    window.google.accounts.id.renderButton(parent, {
      theme: "outline",
      type: "icon",
      shape: "circle",
      size: "large",
    });
    window.google.accounts.id.prompt();
    // const { shopTypeList, providerServiceList } = useLoginUi()
    const { countryList } = useUserUi()
    // if (this.$route.meta.sharedType === 'shop') {
    //   shopTypeList()
    //     .then(({ data }) => {
    //       if (data.statusCode === '200') {
    //         this.shopTypes = data.responseData
    //         this.shopTypeOptions = this.shopTypes.map(x => ({
    //           text: x.name,
    //           value: x.id,
    //         }))
    //       }
    //     })
    //     .catch(error => {
    //       showErrorNotification(this, error)
    //     })
    // }
    // if (this.$route.meta.sharedType === 'provider') {
    //   providerServiceList()
    //     .then(({ data }) => {
    //       if (data.statusCode === '200') {
    //         this.providerServiceOptions = data.responseData.map(x => ({
    //           name: x.service_category_name,
    //           id: x.id,
    //         }))
    //       }
    //     })
    //     .catch(error => {
    //       showErrorNotification(this, error)
    //     })
    // }
    countryList()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.countryList = data.responseData
          const country = data.responseData.map(x => ({
            text: x.country_name,
            value: x.id,
          }))
          country.forEach(element => {
            this.countryOptions.push({
              text: element.text,
              value: element.value,
            })
          })
        } else {
          showDangerNotification(this, data.message)
        }
        hideLoader()
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
    if (localStorage.getItem('userSocialInfo')) {
      const socialInfo = JSON.parse(localStorage.getItem('userSocialInfo'))
      this.registrationInfo.userEmail = socialInfo.email
      this.registrationInfo.firstName = socialInfo.first_name
      this.registrationInfo.lastName = socialInfo.last_name
    }
    window.socialLoginParams = payload => {
      this.makeSocialLogin(payload)
    }
  },
  methods: {
    OnGoogleAuthSuccess (idToken) {
      const payload = jwt_decode(idToken)
      this.makeSocialLogin({
        social_unique_id: payload.sub,
        login_by: 'GOOGLE',
        first_name: payload.given_name,
        last_name: payload.family_name,
        email: payload.email,
        picture: payload.picture,
      })
    },
    OnGoogleAuthFail () {
      showDangerNotification(this, 'Failed to login !!')
    },
    getUserData() {
      if (!this.FB) return
      this.FB.api('/me', 'GET', { fields: 'id,first_name,last_name,email,link,gender,locale,picture' }, response => {
        if (response.error) {
          showDangerNotification(this, 'Failed to login !!')
          return
        }else if(response.email == null || response.email == '' || typeof response.email === typeof undefined){
          showDangerNotification(this, 'Email address not found on your Facebook account try alternate method for register (Manual/Google)!!')
          return false
        }
        if (window.socialLoginParams) {
          window.socialLoginParams({
            social_unique_id: response.id,
            login_by: 'FACEBOOK',
            first_name: response.first_name,
            last_name: response.last_name,
            email: response.email,
            picture: response.picture && response.picture.data ? response.picture.data.url : '',
          })
        }
      })
    },
    sdkLoaded(payload) {
      this.FB = payload.FB
      if (this.isConnected) this.getUserData()
    },
    onLogin() {
      this.isConnected = true
      this.getUserData()
    },
    makeSocialLogin(payload) {
      if(payload.login_by == 'GOOGLE' && payload.login_by == 'FACEBOOK'){
        this.isSocialLogin = true
      }
      const formData = new FormData()
      formData.append('social_unique_id', payload.social_unique_id)
      formData.append('login_by', payload.login_by)
      formData.append('first_name', payload.first_name)
      formData.append('last_name', payload.last_name)
      formData.append('email', payload.email)
      formData.append('picture', payload.picture)
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      formData.append('gender', this.registrationInfo.gender)
      window.localStorage.setItem('userSocialInfo', JSON.stringify(payload))
      if(typeof payload.login_by === typeof undefined || (payload.login_by != 'GOOGLE' && payload.login_by == 'FACEBOOK')){
        formData.append('password', payload.social_unique_id)
        formData.append('password_confirmation', payload.social_unique_id)
      }
      if (this.registrationInfo.phoneCode) {
        formData.append('country_code', this.registrationInfo.phoneCode)
        formData.append('iso2', `+${this.registrationInfo.phoneCode}`)
      }
      if (this.registrationInfo.store_name) {
        formData.append('store_name', this.registrationInfo.store_name)
      }
      if (this.registrationInfo.store_type_id) {
        formData.append('store_type_id', this.registrationInfo.store_type_id)
      }
      if (this.$route.meta.sharedType === 'provider') {
        if (Array.isArray(this.registrationInfo.provider_service_id)) {
          this.registrationInfo.provider_service_id.forEach(item => {
            formData.append('provider_service_id[]', item.id)
          })
        }
        if (this.registrationInfo.company_name) {
          formData.append('company_name', this.registrationInfo.company_name)
        }
      }
      const { userRegistration, shopRegistration, providerRegistration } = useLoginUi()
      let callType = null
      if (this.$route.meta.sharedType === 'shop') {
        callType = shopRegistration
      } else if (this.$route.meta.sharedType === 'provider') {
        callType = providerRegistration
      } else {
        callType = userRegistration
      }
      showLoader()
        callType({
          type: this.$route.meta.sharedType,
          data: formData,
        })
          .then(({ data }) => {
            if (data.statusCode === '200') {
              showSuccessNotification(this, 'Registration Successful')
              this.setLoginInformations(data)
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
    },
    setLoginInformations(responseLogin) {
      useJwt.setToken(responseLogin.responseData.access_token)
      useJwt.setRefreshToken(responseLogin.responseData.access_token)
      window.SuperApp.getters.setUserInfo(JSON.stringify(responseLogin.responseData.user))
      window.SuperApp.getters.setUserType(this.$route.meta.sharedType ? this.$route.meta.sharedType : 'user')
      window.SuperApp.getters.setUserAccess(responseLogin.responseData.access_token)
      showSuccessNotification(this, 'Logged in successfully')
      window.SuperApp.getters.setUserLoggedIn(true)
      if (responseLogin.responseData.user.city_id) {
        localStorage.setItem('userSelectedCityId', responseLogin.responseData.user.city_id)
      }
      if (!document.body.classList.contains('modal-open')) {
        window.SuperApp.helper.changeTranslation(this, window.SuperApp.getters.userLang())
        if (this.$route.query.returnUrl) {
          this.$router.push({ path: this.$route.query.returnUrl })
        } else if (window.SuperApp.getters.isUser()) this.$router.push({ name: 'user-profile' })
        else if (window.SuperApp.getters.isProvider() && !window.SuperApp.getters.isAgent()) this.$router.push({ name: 'provider-profile' })
        else if (window.SuperApp.getters.isProvider() && window.SuperApp.getters.isAgent()) this.$router.push({ name: 'provider-profile' })
        else if (window.SuperApp.getters.isStore()) this.$router.push({ name: 'shop-profile' })
        else if (window.SuperApp.getters.isAdmin()) this.$router.push({ name: 'admin-cmspages-faq' })
        window.SuperApp.helper.requestPermission()
      } else {
        window.location.reload()
      }
    },
    loginWithFacebook() {
      fbLogin()
    },
    onCountryChange() {
      this.cityOptions = []
      let cityList = []
      const { cityByCountry } = useUserUi()
      showLoader()
      cityByCountry(this.registrationInfo.countryCode)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const isCountryExist = this.countryList.find(x => x.id === this.registrationInfo.countryCode)
            if (isCountryExist) {
              isCountryExist.city = data.responseData
              cityList = isCountryExist.city.map(x => ({
                text: x.city_name,
                value: x.id,
              }))
            }
            this.registrationInfo.cityCode = ''
            cityList.forEach(element => {
              this.cityOptions.push({
                text: element.text,
                value: element.value,
              })
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onUpdate(payload) {
      this.phoneNumberPayload = payload
      this.registrationInfo.phoneCode = payload.countryCallingCode
    },
    sendOTP() {
      const { userSendOTP } = useLoginUi()
      showLoader()
      let payload = {}
      if (this.forgetType === 'email') {
        payload = {
          email: this.registrationInfo.userEmail,
        }
      } else {
        payload = {
          country_code: this.registrationInfo.phoneCode,
          mobile: this.phoneNumberPayload.nationalNumber,
        }
      }
      userSendOTP({
        data: {
          ...payload,
          salt_key: window.SuperApp.getters.saltKey(),
          account_type: this.forgetType,
        },
        query: this.$route.meta.sharedType ? this.$route.meta.sharedType : 'user',
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.registrationSteps.verifyOtp = true
            showSuccessNotification(this, 'OTP sent successfully')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    verifyOTP() {
      const { userVerifyOTP } = useLoginUi()
      showLoader()
      userVerifyOTP({
        country_code: this.registrationInfo.phoneCode,
        mobile: this.phoneNumberPayload ? this.phoneNumberPayload.nationalNumber : '',
        otp: this.registrationInfo.otp,
        salt_key: window.SuperApp.getters.saltKey(),
        account_type: this.forgetType,
        email: this.registrationInfo.userEmail,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'OTP verified successfully')
            this.registrationSteps.normal = true
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    registerUser() {
      const formData = new FormData()
      if (this.phoneNumberPayload && this.phoneNumberPayload.nationalNumber) {
        formData.append('mobile', this.phoneNumberPayload.nationalNumber)
      }else{
        formData.append('email', this.registrationInfo.userEmail)
      }
      formData.append('otp', this.registrationInfo.otp)
      if (this.$route.meta.sharedType === 'user') {
        formData.append('first_name', this.registrationInfo.firstName)
        formData.append('last_name', this.registrationInfo.lastName)
        if (this.registrationInfo.gender) {
          formData.append('gender', this.registrationInfo.gender)
        }
        formData.append('country_id', this.registrationInfo.countryCode)
        formData.append('city_id', this.registrationInfo.cityCode)
        if (this.registrationInfo.referralCode) {
          formData.append('referral_code', this.registrationInfo.referralCode)
        }
        formData.append('salt_key', window.SuperApp.getters.saltKey())
      }
      // eslint-disable-next-line eqeqeq
      if (this.registrationInfo.password != this.registrationInfo.confirmPassword) {
        showDangerNotification(this, 'Confirm password should be same as Password')
        return
      }
      formData.append('password', this.registrationInfo.password)
      formData.append('password_confirmation', this.registrationInfo.confirmPassword)
      if (this.registrationInfo.phoneCode) {
        formData.append('country_code', this.registrationInfo.phoneCode)
        formData.append('iso2', `+${this.registrationInfo.phoneCode}`)
      }
      if (this.registrationInfo.store_name) {
        formData.append('store_name', this.registrationInfo.store_name)
      }
      if (this.registrationInfo.store_type_id) {
        formData.append('store_type_id', this.registrationInfo.store_type_id)
      }
      if (this.$route.meta.sharedType === 'provider') {
        if (Array.isArray(this.registrationInfo.provider_service_id)) {
          this.registrationInfo.provider_service_id.forEach(item => {
            formData.append('provider_service_id[]', item.id)
          })
        }
        if (this.registrationInfo.company_name) {
          formData.append('company_name', this.registrationInfo.company_name)
        }
      }
      const { userRegistration, shopRegistration, providerRegistration } = useLoginUi()
      let callType = null
      if (this.$route.meta.sharedType === 'shop') {
        callType = shopRegistration
      } else if (this.$route.meta.sharedType === 'provider') {
        callType = providerRegistration
      } else {
        callType = userRegistration
      }
      showLoader()
      callType({
        type: this.$route.meta.sharedType,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Registration Successful')
            this.setLoginInformations(data)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    validationForm() {
      this.$refs.registrationValidation.validate().then(success => {
        if (success) {
          if (this.forgetType !== 'email') {
            if (!this.phoneNumberPayload) {
              showDangerNotification(this, 'Please enter phone number')
              return
            }
            if (!this.phoneNumberPayload.isValid) {
              showDangerNotification(this, 'Please enter proper phone number')
              return
            }
          } else if (!this.registrationInfo.userEmail) {
            showDangerNotification(this, 'Please enter email')
            return
          }
          if (this.registrationSteps.normal) {
            this.registerUser()
          } else if (this.registrationSteps.verifyOtp) {
            this.verifyOTP()
          } else if (this.registrationSteps.sendOtp) {
            this.sendOTP()
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-footer-btn {
  button {
    border: 0;
    background-color: transparent !important;
  }
}

.registration-page {
  .multiselect__tags {
    border: 1px solid #d8d6de !important;
  }
}

.auth-wrapper.auth-v2.modal-register-page {
  min-height: auto !important;
  padding-bottom: 20px;

  .auth-inner {
    height: auto;
  }
}
</style>

<style scoped>
.demo-inline-spacing {
  justify-content: center;
}
</style>
