<template>
  <div v-if="message && message.from">
    <p
      v-if="isSameDate(message, prev) || dataIndex == 0"
      class="mb-0 text-center font-16 font-weight-bolder mt-1"
    >
      {{ formatDate(message.time) }}
    </p>
    <div
      v-if="message.from.id === currentUser.id && message.from.userType === userTypeInfo()"
      class="chat__mymessage"
      :class="[isSame ? '' : 'chat__first', message.picture ? 'd-block text-right' : '', message.message ? '' : 'd-flex']"
    >
      <div class="chat-wrapper-with-image">
        <p class="chat__yourmessage__time">
          {{ formatTime(message.time) }}
        </p>
        <p
          v-if="message.message"
          class="chat__mymessage__paragraph"
        >
          {{ message.message }}
        </p>
      </div>
      <img
        v-if="message.picture"
        :src="$helpers.imageHelper(message.picture)"
        class="message-images"
        @click="enlarge($event)"
        @error="$helpers.imageError"
      >
    </div>
    <div
      v-else
      class="chat__yourmessage"
      :class="[isSame ? '' : 'chat__first']"
    >
      <div class="chat__yourmessage__avartar">
        <img
          v-if="!isSame"
          :src="$helpers.imageHelper(avatar)"
          class="chat__yourmessage__img"
          @click="enlarge($event)"
          @error="$helpers.imageError"
        >
      </div>
      <div>
        <p
          v-if="!isSame"
          class="chat__yourmessage__user mb-0"
        >
          {{ message.from.name }}
        </p>
        <div
          class="chat__yourmessage__p"
          :class="[message.picture ? 'd-block text-left' : '', message.message ? '' : 'd-flex']"
        >
          <div class="chat-message-left-wrapper">
            <p
              v-if="message.message"
              class="chat__yourmessage__paragraph"
            >
              {{ message.message }}
            </p>
            <img
              v-if="message.picture"
              :src="$helpers.imageHelper(message.picture)"
              class="message-images"
              @click="enlarge($event)"
              @error="$helpers.imageError"
            >
            <p class="chat__yourmessage__time">
              {{ formatTime(message.time) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import eventBus from '@/libs/event-bus'

export default {
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    prev: {
      type: [Object, Array],
      default: () => {},
    },
    dataIndex: {
      type: [Number],
      default: -1,
    },
  },
  data() {
    return {
      isSame: false,
      currentUser: window.SuperApp.getters.userInfo(),
      avatar: '',
    }
  },
  created() {
    this.isSame = this.isSamePerson(this.message, this.prev)
    if (this.message?.from.avatar) {
      this.avatar = this.message?.from.avatar
    }
  },
  methods: {
    enlarge($event) {
      eventBus.$emit('show-enlarged', $event.target.src)
    },
    userTypeInfo() {
      // eslint-disable-next-line no-nested-ternary
      return this.currentUser.store_name ? 'SHOP' : this.currentUser.type ? this.currentUser.type : this.currentUser.user_type
    },
    formatTime(time) {
      return moment(new Date(time)).format('HH:mm A')
    },
    formatDate(date) {
      return moment(new Date(date)).format('YYYY-MM-DD')
    },
    isSameDate(prev, current) {
      if (!current[0]) {
        return false
      }
      if (this.formatDate(prev.time) !== this.formatDate(current[0].time)) {
        return true
      }
      return false
    },
    isSamePerson(msg, prev) {
      if (prev === null) {
        return false
      }
      if (prev[0]?.from.id === msg?.from.id && prev[0]?.from.userType === msg?.from.userType) {
        return true
      }
      return false
    },
  },
}
</script>

<style>
.message-images {
  width: 150px;
  object-fit: contain;
  margin-top: 10px;
  margin-left: 10px;
}

.chat__mymessage {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin: 0;
  min-height: 40px;
  line-break: anywhere;
}

.chat__mymessage__paragraph {
  margin: 0.4rem 0 0 1rem;
  border-radius: 20px 20px 0px 20px;
  max-width: 180px;
  background-color: #8d84e7;
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
}

/* .chat__first {
  margin-top: 2rem;
} */

.chat__yourmessage {
  display: flex;
}

.chat__yourmessage__avartar {
  width: 40px;
  margin-right: 1rem;
}

.chat__yourmessage__img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat__yourmessage__user {
  font-size: 14px;
  font-weight: 700;
  color: #292929;
  margin-top: 0;
  margin-block-end: 0rem;
}

.chat__yourmessage__p {
  display: flex;
  align-items: flex-end;
  line-break: anywhere;
}

.chat-wrapper-with-image {
  display: flex;
  justify-content: right;
  align-items: flex-end;
}

.chat__yourmessage__paragraph {
  margin: 0.4rem 1rem 0 0;
  border-radius: 0px 20px 20px 20px;
  background-color: #f3f3f3;
  max-width: 180px;
  color: #414141;
  padding: 0.8rem;
  font-size: 14px;
}

.chat-message-left-wrapper {
  display: flex;
  align-items: flex-end;
}

.chat-message-left-wrapper .message-images {
  margin-left: 0;
  margin-right: 10px;
}

.chat__yourmessage__time {
  margin: 0;
  font-size: 12px;
  color: #9c9c9c;
}
</style>
