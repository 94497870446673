<template>
  <div class="chat">
    <div class="chat__header">
      <span class="chat__header__greetings"> Chat with {{ isStoreOrProvider ? userName : providerName }} </span>
    </div>
    <chat-list :chat-messaged="msgData" />
    <chat-form @submitMessage="sendMessage" />
  </div>
</template>

<script>
import eventBus from '@/libs/event-bus'
import { useUserUi } from '@/views/users/useUser'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import ChatList from './ChatList.vue'
import ChatForm from './ChatForm.vue'

export default {
  components: {
    ChatList,
    ChatForm,
  },
  props: {
    chatType: {
      type: String,
      default: '',
    },
    dataId: {
      type: [String, Number],
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    providerName: {
      type: String,
      default: '',
    },
    isAgent: {
      type: Boolean,
      default: false,
    },
    isAgentChat: {
      type: Boolean,
      default: false,
    },
    isUserChat: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: [String, Number],
      default: '',
    },
    storeProviderId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      userData: null,
      msgData: [],
    }
  },
  computed: {
    isStoreOrProvider() {
      return window.SuperApp.getters.isStore() || window.SuperApp.getters.isProvider()
    },
  },
  created() {
    this.userData = window.SuperApp.getters.userInfo()
    if (this.userData && !this.userData.first_name) {
      this.userData.first_name = this.userData.store_name
      this.userData.last_name = ''
    }
  },
  mounted() {
    eventBus.$on('user-messaged', this.userMessaged)
    this.getChatsForUser()
  },
  beforeMount() {
    eventBus.$off('user-messaged', this.userMessaged)
  },
  methods: {
    scrollDown() {
      setTimeout(() => {
        const element = document.getElementById('chat__body')
        element.scrollTop = element.scrollHeight
      }, 0)
    },
    userMessaged(data) {
      let canAddMessage = false
      // eslint-disable-next-line eqeqeq
      if (data.chatType === this.chatType && data.dataId == this.dataId) {
        canAddMessage = true
      }
      if (canAddMessage) {
        if (data.picture) {
          this.getChatsForUser(true)
          window.SuperApp.actions.newMessage()
        } else if (this.isAgentChat && data.chatFor === 'AGENT') {
          this.msgData.push(data)
          window.SuperApp.actions.newMessage()
        } else if (this.isUserChat && data.chatFor === 'USER') {
          this.msgData.push(data)
          window.SuperApp.actions.newMessage()
        } else if (data.chatFor !== 'AGENT' && data.chatFor !== 'USER') {
          this.msgData.push(data)
          window.SuperApp.actions.newMessage()
        }
        this.scrollDown()
      }
    },
    getChatsForUser(forceHide = false) {
      if (!forceHide) {
        showLoader()
      }
      const { getChatsForUser } = useUserUi()

      getChatsForUser({
        id: this.dataId,
        type: this.chatType,
        agent: this.isAgent ? '/agent' : '',
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const userMessages = data.responseData[0]
            if (userMessages) {
              this.msgData = []
              userMessages.data.forEach(item => {
                try {
                  if (item && item.message) {
                    const payload = JSON.parse(item.message)
                    payload.picture = item.picture
                    this.msgData.push(payload)
                  }
                } catch (error) {
                  console.log(error)
                }
              })
              this.msgData = this.msgData.filter(x => x.message || x.picture)
              if (this.isAgentChat) {
                this.msgData = this.msgData.filter(x => x.chatFor === 'AGENT')
              } else if (this.isUserChat) {
                this.msgData = this.msgData.filter(x => x.chatFor === 'USER')
              } else {
                this.msgData = this.msgData.filter(x => x.chatFor !== 'AGENT' && x.chatFor !== 'USER')
              }
            }
            hideLoader()
            this.scrollDown()
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    userTypeInfo() {
      // eslint-disable-next-line no-nested-ternary
      return this.userData.store_name ? 'SHOP' : this.userData.type ? this.userData.type : this.userData.user_type
    },
    addChatsMessage(formData) {
      return new Promise(resolve => {
        showLoader()
        const { addChats } = useUserUi()

        addChats(formData)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              hideLoader()
            } else {
              showDangerNotification(this, data.message)
            }
            resolve(true)
          })
          .catch(error => {
            resolve(true)
            showErrorNotification(this, error)
          })
      })
    },
    async sendMessage(message) {
      const username = `${this.userData.first_name} ${this.userData.last_name}`
      const avatar = this.userData.picture
      const userTypeInfo = this.userTypeInfo()
      const payload = {
        from: {
          name: username,
          avatar,
          id: this.userData.id,
          userType: userTypeInfo,
        },
        // eslint-disable-next-line no-nested-ternary
        chatFor: this.isAgentChat ? 'AGENT' : this.isUserChat ? 'USER' : '',
        chatType: this.chatType,
        message: message.picture ? '' : message,
        dataId: this.dataId,
        time: new Date(),
      }
      const formData = new FormData()
      formData.append('id', this.dataId)
      formData.append('admin_service', this.chatType)
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      formData.append('user_name', this.userName)
      formData.append('provider_name', this.providerName)
      formData.append('type', userTypeInfo)
      formData.append('user_id', this.customerId)
      formData.append(this.chatType === 'SERVICE' ? 'provider_id' : 'store_id', this.storeProviderId)
      if (message.picture) {
        formData.append('picture', message.picture)
      }
      formData.append('message', JSON.stringify(payload))
      console.time()
      await this.addChatsMessage(formData)
      console.timeEnd()
      setTimeout(
        () => {
          eventBus.$emit('chat-message', {
            ...payload,
            picture: message.blobUrl,
          })
        },
        message.blobUrl ? 1000 : 0,
      )

      this.msgData.push({
        ...payload,
        picture: message.blobUrl,
      })

      this.scrollDown()
    },
  },
}
</script>

<style scoped>
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat__header {
  background: #7367f0;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 24px 24px 0px 0px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 700;
}

.chat__header__greetings {
  color: #fff;
}
</style>
