<template>
  <div
    id="chat__body"
    class="chat__body"
  >
    <chat-message
      v-for="(msg, index) in chatMessaged"
      :key="index"
      :message="msg"
      :data-index="index"
      :prev="[index == 0 ? null : chatMessaged[index - 1]]"
    />
  </div>
</template>

<script>
import ChatMessage from './ChatMessage.vue'

export default {
  components: {
    ChatMessage,
  },
  props: {
    chatMessaged: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>
.chat__body {
  padding: 0;
  max-height: 65vh;
  overflow-y: auto;
  min-height: 65vh;
  scroll-behavior: smooth;
  padding-right: 10px;
}
</style>
